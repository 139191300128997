import './style.css'
import Six from '../Number/six'
import Three from '../Number/three'
import Four from '../Number/four'
import Number from '../Number'
import useGetData from '../../utils/useGetData'
import React, { useState, useEffect } from 'react'
import CustomTable from './custom'

function Line(props) {
    return <div className={'title-line ' + props.className} style={{
        margin: '0 0 17px'
    }}>
        <div className='title-left'>
            {props.config.title}<small>({props.config.unit})</small>
        </div>
        <div className='title-right'>
            {props.config.num}
        </div>
    </div>
}

function Left(props) {
    const [dataList] = useGetData('left')
    const [dataObj, setDataObj] = useState({})
    const [totalWorker, setTotalWorker] = useState(0)

    const [todayInsertNum, setTodayInsertNum] = useState(0)

    const [removeBlankNum, setRemoveBlankNum] = useState(0)
    const [preTotalNum, setPreTotalNum] = useState(0)

    useEffect(() => {
        setDataObj(dataList[0] || {})
    }, [dataList])

    // useEffect(() => {
    //     splitData()
    //     store.dispatch({type: 'updateDataIndex',pyload: {dataIndex: 18}})
    // },[])

    // useEffect(() => {
    //     console.log(store.getState())
    // },[store])

    const workerColumns = [
        {
            title: '洗果',
            dataIndex: 'clean',
            align: 'center',
            render: (col, record, index) => (
                <Number value={record.clean} />
            ),
        },
        {
            title: '打包',
            dataIndex: 'pick',
            align: 'center',
            render: (col, record, index) => (
                <Number value={record.pick} />
            ),
        },
        {
            title: '上车',
            dataIndex: 'take',
            align: 'center',
            render: (col, record, index) => (
                <Number value={record.take} />
            ),
        },
        {
            title: '组数',
            dataIndex: 'group',
            align: 'center',
            render: (col, record, index) => (
                <Number value={record.group} />
            ),
        },
        {
            title: '品控',
            dataIndex: 'quailty',
            align: 'center',
            render: (col, record, index) => (
                <Number value={record.quailty} />
            ),
        },
        {
            title: '调度',
            dataIndex: 'rotate',
            align: 'center',
            render: (col, record, index) => (
                <Number value={record.rotate} />
            ),
        }
    ]

    const insertNumColumns = [
        {
            title: '果农姓名',
            dataIndex: 'name',
            align: 'center'
        },
        {
            title: '每车净重量',
            dataIndex: 'realWeightPerCar',
            align: 'center',
            render: (col, record, index) => (
                <Three value={record.realWeightPerCar} />
            ),
        },
        {
            title: '入库框子数',
            dataIndex: 'insertBlankNum',
            align: 'center',
            render: (col, record, index) => (
                <Three value={record.insertBlankNum} />
            ),
        },
        {
            title: '扣称数',
            dataIndex: 'removeNum',
            align: 'center',
            render: (col, record, index) => (
                <Three value={record.removeNum} />
            ),
        }
    ]

    const preInsertNumColumns = [
        {
            title: '果农姓名',
            dataIndex: 'name',
            align: 'center'
        },
        {
            title: '预计总数量',
            dataIndex: 'preTotalNum',
            align: 'center',
            width: '140px',
            render: (col, record, index) => <>
                <Three value={record.preTotalNum} /><span style={{fontSize: '12px'}}>(万斤)</span>
            </>,
        },
        {
            title: '预估未入库数量',
            dataIndex: 'preNotNum',
            align: 'center',
            width: '140px',
            render: (col, record, index) => <>
                <Three value={record.preNotNum} /><span style={{fontSize: '12px'}}>(万斤)</span>
            </>,
        },
        {
            title: '拉走白框数',
            dataIndex: 'removeBlankNum',
            align: 'center',
            width: '130px',
            render: (col, record, index) => <>
                <Three value={record.removeBlankNum} /><span style={{fontSize: '12px'}}>(个)</span>
            </>,
        }
    ]

    return <div className="main-left">
        <Line
            className='twoDot'
            config={{
                num: <Six value={parseInt(dataObj.todayPrice * 100)} />,
                title: '今日统收价格',
                unit: '元'
            }} />

        <Line config={{
            num: <Six value={todayInsertNum} />,
            title: '今日已入库数量',
            unit: '斤'
        }} />
        <CustomTable columns={insertNumColumns} dataPath="insertNum"  handleData={data => {
            if (data.length == 0) return
            const total = data.reduce((pre, cur) => parseInt(cur.realWeightPerCar) + parseInt(pre), 0)
            setTodayInsertNum(total)
        }}/>

        <Line config={{
            num: <Four value={removeBlankNum} />,
            title: '在外白框数量',
            unit: '个'
        }} />

        <CustomTable columns={preInsertNumColumns} dataPath="preInsertNum" handleData={data => {
            if (data.length == 0) return
            const totalBlank = data.reduce((pre, cur) => parseInt(cur.removeBlankNum) + parseInt(pre), 0)
            setRemoveBlankNum(totalBlank)

            const totalNum = data.reduce((pre, cur) => parseInt(cur.preTotalNum) + parseInt(pre), 0)
            setPreTotalNum(totalNum)
        }}/>

        <Line config={{
            num: <Six value={preTotalNum} />,
            title: '预计未入库数量',
            unit: '斤'
        }} />
        <Line config={{
            num: <Three value={totalWorker} />,
            title: '今日用工数',
            unit: '人'
        }} />

        <CustomTable columns={workerColumns} dataPath="worker" handleData={data => {
            if (data.length == 0) return
            const total = Object.values(data[0]).reduce((cur, pre) => parseInt(cur) + parseInt(pre), 0)
            setTotalWorker(total)
        }}/>

    </div>
}

export default Left
