import './style.css'
import { Grid } from '@arco-design/web-react'
import React, { useState, useEffect } from 'react'
import Six from './../Number/six'
import useAnimateNumber from 'use-animate-number'
import useGetData from '../../utils/useGetData'

import { useSelector, useDispatch } from 'react-redux'

import { splitData } from '../../utils'

const Row = Grid.Row
const Col = Grid.Col

function Pannel(props) {
    return <div className="main-center-pannel">
        <div className='title-line' style={{
            margin: '0 0 17px'
        }}>
            <div className='title-left'>
                {props.config.title}<small>({props.config.unit})</small>
            </div>
        </div>
        <div className='numShow'>
            <div className='inner'><Six value={props.config.value} numStyle={{
                fontSize: '48px',
                padding: '15px 9px'
            }} /></div>
        </div>
    </div>
}


function Center() {
    const [value, setValue] = useAnimateNumber(0, {
        duration: 1000,
        enterance: false,
        direct: true,
        disabled: false,
        decimals: 0
    })
    
    const [yestodayNotSend] = useGetData('yestodayNotSend')

    const [todayTotal, setTodayTotal] = useState(0)

    const dataIndex = useSelector((state) => state.dataIndex)
    const orderData = useSelector((state) => state.dataList)

    useEffect(() => {
        const currSecond = splitData(1)
        if (currSecond < 13) window.location.reload()
        if (!orderData || !orderData.length) return
        const result = orderData.slice(0, dataIndex)
        let allBox = 0, allTotal = 0
        result.forEach(item => {
            allBox = allBox + parseInt(item.num || 0)
            allTotal = allTotal + parseInt(item.num || 0) * parseInt(item.price || 0)
            setTodayTotal(allBox)
            setValue(allTotal)
        })
    }, [dataIndex, orderData])

    return <div className="main-center">
        <Row justify='space-between'>
            <Col span={11}>
                <Pannel config={{
                    title:'昨日未发出数',
                    unit: '单位：箱',
                    value: yestodayNotSend ? (yestodayNotSend[0] || {}).yestodayNotSend : 0
                }} />
            </Col>
            <Col span={11}>
                <Pannel config={{
                    title:'今日收单数',
                    unit: '单位：箱',
                    value: todayTotal
                }} />
            </Col>
        </Row>
        <div className='center-num'>
            <div className='title-line' style={{
                margin: '0 0 17px'
            }}>
                <div className='title-left'>
                    今日应收货款总金额<small>(单位：元)</small>
                </div>
            </div>
            <div className='center-num-box'>
                {Number(value).toLocaleString()}
            </div>
            <h2 className='center-title'>实时订单数据</h2>
        </div>
        
    </div>
}

export default Center
