
import { useEffect, useState } from 'react'
import _ from 'lodash'

function useScroll(dataList, tableRef, length = 5, speed = 20) {
    const [cloneData, setCloneData] = useState([])
    const [timer, setTimer] = useState(null)

    useEffect(() => {
      if (dataList.length > length) {
        const arr = _.cloneDeep(dataList).concat(_.cloneDeep(dataList))
        const newArr = arr.map((item,index) => {
          item.key = index + 1
          return item
        })
        setCloneData(newArr)
      } else {
        setCloneData(dataList)
        if (timer) clearInterval(timer)
      }
    }, [dataList])

    useEffect(() => {
      const bodyDom = tableRef.current.querySelector('.arco-table-body')
      const tBody = bodyDom.querySelector('tbody')
      bodyDom.scrollTop = 0
      if (timer) clearInterval(timer)
      setTimer(setInterval(() => {
        const sTop = bodyDom.scrollTop
        if( sTop >= (tBody.offsetHeight / 2)) {
          bodyDom.scrollTop = 0
        } else {
          bodyDom.scrollTop = sTop + 1
        }
      }, speed))
    }, [cloneData])

    return [cloneData]
}

export default useScroll
