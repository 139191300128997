import { legacy_createStore as createStore} from 'redux'

import { splitData } from '../utils'

const initialState = {
  dataIndex: 0,
  dataList: []
}

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case 'updateDataIndex':
			return {
				dataIndex: splitData(action.pyload.data.length),
				dataList: action.pyload.data
			}
		default:
			return state
	}
}

export default createStore(reducer)
