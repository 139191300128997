import { Table } from '@arco-design/web-react'
import useGetData from '../../utils/useGetData'

const columns = [
    {
      title: '果型（包装）',
      dataIndex: 'size',
      width: '210px',
      render: (col, record, index) => {
        const arr = record.size.split(',')
        return <>
            <b>{arr[0]}</b><span style={{fontSize: '15px', fontWeight: 500}}>{arr[1]}</span>
        </>
      },
    },
    {
      title: <>净重<small>(斤)</small></>,
      dataIndex: 'weight',
      render: (col, record, index) => (
        <span className='cell-bg-blue'>
          {record.weight}
        </span>
      ),
    },
    {
      title: <>价格<small>(元)</small></>,
      dataIndex: 'price',
      render: (col, record, index) => (
        <span className='cell-bg-red'>
          {record.price}
        </span>
      ),
    }
  ]

function TableOne() {
    const [dataList] = useGetData('priceTable')

    return <Table columns={columns}
        className="custom-table"
        data={dataList}
        pagination={false}
        virtualized={true}
        hover={false}
        border={{ wrapper: true, headerCell: false, bodyCell: false, cell: false }} />
}

export default TableOne
