import useAnimateNumber from 'use-animate-number'
import React, { useState, useEffect } from 'react'

function Single(props) {
    const [value, setValue] = useAnimateNumber(0, {
        duration: 1000,
        enterance: false,
        direct: true,
        disabled: false,
        decimals: 0
    })

    useEffect(() => {
        if (!props.value) return
        setValue(props.value)
    }, [props.value])
    
    return <>
        <span style={props.numStyle} className='number-item'><i>{value}</i></span>
    </>
}

export default Single