import './style.css'
import React, { useState, useEffect } from 'react'
import { Grid } from '@arco-design/web-react'
import useGetData from '../../utils/useGetData'
import { getTime } from '../../utils'

const Row = Grid.Row
const Col = Grid.Col

const noticeIndex = 0

function Navbar() {
    const [timer, setTimer] = useState(null)
    const [timeData, setTimeData] = useState({})
    const [configList] = useGetData('config')
    const [config, setConfig] = useState({})
    useEffect(() => {
        setConfig(configList[0] || {})
    }, [configList])
    useEffect(() => {
        setTimer(setInterval(() => {
            setTimeData(getTime())
        }, 1000))
        return function cleanup() {
            clearInterval(timer)
            setTimer(null)
        }
    }, [])

    const [noticeList] = useGetData('notice')
    const [noticeClass, setNoticeClass] = useState('')
    const [currentNotice, setCurrentNotice] = useState('')
    const [currentNoticeIndex, setCurrentNoticeIndex] = useState(0)
    const [noticeTimer, setNoticeTimer] = useState(null)
    const spanRef = React.createRef()

    useEffect(() => {
        spanRef.current.style.setProperty('--animate-duration', '.3s')
        if (noticeTimer) {
            clearInterval(noticeTimer)
            setNoticeTimer(null)
        }
        if (!noticeList.length) return
        setCurrentNotice(noticeList[currentNoticeIndex]?.notice || '')
        if (noticeList.length > 1) {
            setNoticeTimer(setInterval(() => setCurrentNoticeIndex(pre => {
                let newIndex = pre + 1
                if (newIndex >= noticeList.length) newIndex = 0
                return newIndex
            }), 4000))
        }

        return function cleanup() {
            clearInterval(noticeTimer)
            setNoticeTimer(null)
        }
    }, [noticeList])

    useEffect(() => {
        setNoticeClass('animate__animated animate__backOutUp')
        setTimeout(() => {
            setCurrentNotice(noticeList[currentNoticeIndex]?.notice || '')
            setNoticeClass('animate__animated animate__backInUp')
        }, 300)
    }, [currentNoticeIndex])


    return (<div className="nav">
        <Row>
            <Col span={6}>
                <div className='nav-notice'>
                    <img src={require('./../../assets/images/icon-voice.png')} alt='' />
                    <span ref={spanRef} className={noticeClass}>{currentNotice}</span>
                </div>
            </Col>
            <Col span={12}>
                <div className='nav-title'>
                    <img src={require('./../../assets/images/logo.png')} alt='' />
                    <span>{config.systemName}</span>
                </div>
            </Col>
            <Col span={6}>
                <div className='nav-right'>
                    <div className='nav-time'>
                        <img src={require('./../../assets/images/icon-weather.png')} alt='' />
                        <span>{timeData.h}:{timeData.m}</span>
                        <div>
                            <p>{timeData.week}</p>
                            <p>{timeData.year}年{timeData.month}月{timeData.day}日</p>
                        </div>
                    </div>
                </div>
            </Col>
        </Row>
    </div>)
}

export default Navbar
