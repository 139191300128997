
import { useEffect, useState } from 'react';
import { getData } from '../network'

function useGetData(path, time = false) {
    const [storedValue, setStoredValue] = useState([])
    const [timer, setTimer] = useState(null)
    function initData() {
        getData(`${path}.csv`).then(res => {
            setStoredValue(res)
        })
    }
    useEffect(() => {
        initData()
        if (time !== false) {
            setTimer(setInterval(initData, time * 1000))
        }
        return function clearTimer() {
            if (timer) clearInterval(timer)
            setTimer(null)
        }
        
    }, [])

    return [storedValue]
}

export default useGetData
