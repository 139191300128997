import './style.css'
import React, { useState, useEffect } from 'react'
import Single from './single'

function Number(props) {
    const len = 2
    const [numArr, setNumArr] = useState([])
    useEffect(() => {
        const propsNumArr = (props?.value || 0).toString().split('')
        if (propsNumArr.length < len) {
            const max = len - propsNumArr.length
            for(let i = 0; i < max;i++) {
                propsNumArr.unshift(0)
            }
        } else {
            propsNumArr.splice(0, propsNumArr.length - len)
        }
        setNumArr(propsNumArr.map(item => parseInt(item)))
    }, [props.value])
    return <>
        <Single value={numArr[0]} numStyle={props.numStyle} />
        <Single value={numArr[1]} numStyle={props.numStyle} />
    </>
}

export default Number
