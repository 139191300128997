
import './style.css'
import React, { useState, useEffect } from 'react'
import { Grid } from '@arco-design/web-react'

import Left from './../Left'
import Center from '../Center'
import Right from '../Right'
import Bottom from '../Bottom'
const Row = Grid.Row
const Col = Grid.Col

function Main() {
    return <Row className='main' gutter={0}>
        <Col span={7}>
          <Left />
        </Col>
        <Col span={10}>
          <Center />
        </Col>
        <Col span={7}>
          <Right />
        </Col>
        <Col span={24}>
          <Bottom />
        </Col>
    </Row>
}

export default Main
