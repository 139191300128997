import './style.css'
function Bg() {
    return <div className='bg-wrapper'>
        <div className='bg-circle'></div>
        <div className='bg-left-line'></div>
        <div className='bg-right-line'></div>
    </div>
}

export default Bg
