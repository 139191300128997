import './style.css'
import React, { useState, useEffect } from 'react'
import Single from './single'

function Six(props) {
    const len = 6
    const [numArr, setNumArr] = useState([])
    useEffect(() => {
        if (!props.value) return
        const propsNumArr = props.value.toString().split('')
        const max = len - propsNumArr.length
        if (propsNumArr.length < len) {
            for(let i = 0; i < max; i++) {
                propsNumArr.unshift(0)
            }
        } else {
            propsNumArr.splice(0, propsNumArr.length - len)
        }
        setNumArr(propsNumArr)
    }, [props.value])
    return <>
        <Single value={numArr[0]} numStyle={props.numStyle} />
        <Single value={numArr[1]} numStyle={props.numStyle} />
        <Single value={numArr[2]} numStyle={props.numStyle} />
        <Single value={numArr[3]} numStyle={props.numStyle} />
        <Single value={numArr[4]} numStyle={props.numStyle} />
        <Single value={numArr[5]} numStyle={props.numStyle} />
    </>
}

export default Six
