import { Table } from '@arco-design/web-react'
import useGetData from '../../utils/useGetData'
import useScroll from '../../utils/useScroll'
import React, {  useRef } from 'react'

const columns = [
    {
      title: '省份',
      dataIndex: 'province',
      width: '90px',
      render: (col, record, index) => (
        <span className='cell-blue-radius'>{record.province}</span>
      ),
      align: 'center'
    },
    {
      title: '地区',
      dataIndex: 'area',
      ellipsis: true
    }
  ]
function TableTwo() {
    const [dataList] = useGetData('stopTable')
    const tableRef = useRef(null)
    const [cloneData] = useScroll(dataList, tableRef)

    return <div ref={tableRef}>
      <Table columns={columns}
        className="custom-table"
        data={cloneData}
        pagination={false}
        hover={false}
        stripe={true}
        scroll={{
          y: 258
        }}
        border={{ wrapper: true, headerCell: false, bodyCell: false, cell: false }} />
    </div>
}

export default TableTwo
