import './style.css'
import TableOne from './table1'
import TableTwo from './table2'

function Right() {
    return <div className="main-right">
        <h2 className='title-center'>今日供应价</h2>
        <TableOne />
        <h2 className='title-center' style={{marginTop: '10px'}}>快递停发区域</h2>
        <TableTwo />
    </div>
}

export default Right
