import './style/layout.css'
import React, { useEffect, useState } from 'react'
import { updateScale } from './utils'
import Bg from './components/Bg'
import Navbar from './components/Navbar'
import Main from './components/Main'

function Layout() {
  const [transform, setTransform] = useState({})
  
  useEffect(() => {
    updateScale(setTransform)
    window.addEventListener('resize', () => {
      updateScale(setTransform)
    })
  }, [])
  return (
    <div className="app" style={transform}>
      <Bg />
      <div className='app-inner-box'>
        <Navbar />

        <Main />
      </div>
    </div>
  );
}

export default Layout;
