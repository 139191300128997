import { Table } from '@arco-design/web-react'
import './style.css'
import useGetData from '../../utils/useGetData'

import React, {  useRef, useEffect, useState } from 'react'
import { getTime } from '../../utils'
import { useSelector, useDispatch } from 'react-redux'

const time = getTime()

const columns = [
    {
      title: '时间',
      dataIndex: 'time',
      ellipsis: true,
      align: 'center',
      render: (col, record, index) => <>
        {time.year}-{time.month}-{time.day}
      </>
    },
    {
        title: '规格',
        dataIndex: 'size',
        ellipsis: true,
        align: 'center'
    },
    {
        title: '收货人姓名',
        dataIndex: 'name',
        ellipsis: true,
        align: 'center'
    },
    {
        title: '电话',
        dataIndex: 'phone',
        ellipsis: true,
        align: 'center',
        render: (col, record, index) => <>
          {('' + record.phone).replace(/(\d{3})\d{4}(\d{4})/, "$1****$2")}
        </>
    },
    {
        title: '收货地址',
        dataIndex: 'address',
        ellipsis: true,
        width: '300px',
        align: 'center'
    },
    {
      title: '数量',
      dataIndex: 'num',
      width: '90px',
      render: (col, record, index) => (
        <span className='cell-blue-radius'>{record.num}</span>
      ),
      align: 'center'
    },
    {
      title: '单价（元/箱）',
      dataIndex: 'price',
      width: '220px',
      render: (col, record, index) => (
        <span>￥{record.price}</span>
      ),
      align: 'center'
    },
    {
      title: '订单备注',
      dataIndex: 'remark',
      ellipsis: true,
      align: 'center'
    }
  ]

function Bottom() {
  const [dataList] = useGetData('orderTable')
  const tableRef = useRef(null)
  const [newData, setNewData] = useState([])
  const [timer, setTimer] = useState(null)
  const dataIndex = useSelector((state) => state.dataIndex)
  const dispatch = useDispatch()

  function setData() {
    if(dataIndex <= 0) return
    setNewData(dataList.slice(dataIndex-6, dataIndex))
  }

  useEffect(() => {
    if (!dataList || !dataList.length) return
    const data = dataList
    dispatch({type: 'updateDataIndex', pyload: {data}})
    setTimer(setInterval(() => {
      dispatch({type: 'updateDataIndex', pyload: {data}})
    }, 5000))

    return function cleanTimer() {
      clearInterval(timer)
      setTimer(null)
    }
  }, [dataList])

  useEffect(() => {
    setData()
  }, [dataIndex])

  return <div className='main-bottom' ref={tableRef}>
      <Table columns={columns}
      className="custom-table custom-table-bottom"
      data={newData}
      noDataElement='当前时间没有新的订单'
      pagination={false}
      hover={false}
      stripe={true}
      scroll={{
        y: 310
      }}
      border={{ wrapper: true, headerCell: false, bodyCell: false, cell: false }} />
  </div>
}

export default Bottom
