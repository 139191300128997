// 整体缩放页面

export function updateScale(setTransform) {
    const viewBox = {
        width: window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth,
        height: window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
    }
    const originBox = { width: 1920, height: 1080 }

    const baseProportion = parseFloat((originBox.width / originBox.height).toFixed(5))

    const currentRate = parseFloat((viewBox.width / viewBox.height).toFixed(5))
    const scale = {}

    if (currentRate > baseProportion) {
        // 表示更宽
        scale.width = ((viewBox.height * baseProportion) / originBox.width).toFixed(5)
        scale.height = (viewBox.height / originBox.height).toFixed(5)
        scale.left = (viewBox.width - (viewBox.height * baseProportion)) / 2
    } else {
        // 表示更高
        scale.height = ((viewBox.width / baseProportion) / originBox.height).toFixed(5)
        scale.width = (viewBox.width / originBox.width).toFixed(5)
        scale.top = (viewBox.height - (viewBox.width / baseProportion)) / 2
    }
    setTransform({
        transform: `scale(${scale.width}, ${scale.height})`,
        top: `${scale.top || 0}px`,
        left: `${scale.left || 0}px`
    })
}


// 获取时间

export function getTime() {
    const data = new Date()
    const year = data.getFullYear()
    const month = (data.getMonth()+1).toString().padStart(2, '0')
    const day = (data.getDate()).toString().padStart(2, '0')
    let week = ''
    switch(data.getDay()) {
        case 1:
            week = '星期一'
            break
        case 2:
            week = '星期二'
            break
        case 3:
            week = '星期三'
            break
        case 4:
            week = '星期四'
            break
        case 5:
            week = '星期五'
            break
        case 6:
            week = '星期六'
            break
        case 0:
            week = '星期日'
            break
        default :
            week = ''
    }
    const h = (data.getHours()).toString().padStart(2, '0')
    const m = (data.getMinutes()).toString().padStart(2, '0')
    return {
        year, month, day, week, h, m
    }
}

export function splitData(total = 0) {
    if(!total) return 0
    let preSingle = total / (24 * 60 * 60)
    if (total === 1) preSingle = 1
    const currDateObj = getTime()
    const currentTime = new Date().getTime()
    const startTime = new Date(`${currDateObj.year}-${currDateObj.month}-${currDateObj.day} 00:00`).getTime()
    const timeIndex = parseInt((currentTime - startTime) / 1000 * preSingle)
    // const duringTime = 24*60*60*1000 / data
    return timeIndex
}


