import { Table } from '@arco-design/web-react'
import './style.css'
import useGetData from '../../utils/useGetData'
import useScroll from '../../utils/useScroll'
import React, {  useRef, useEffect } from 'react'

function CustomTable(props) {
    const during = props.during || false
    const [dataList] = useGetData(props.dataPath, during)
    const tableRef = useRef(null)
    const [cloneData] = useScroll(dataList, tableRef, 1, 70)

    useEffect(() => {
        props.handleData&&props.handleData(dataList)
    }, [dataList])

    return <div ref={tableRef}>
        <Table columns={props.columns}
        className="custom-table custom-table-bottom origin-table-header"
        data={cloneData}
        pagination={false}
        hover={false}
        stripe={true}
        scroll={{
          y: 46
        }}
        border={{ wrapper: true, headerCell: false, bodyCell: false, cell: false }} />
    </div>
}

export default CustomTable