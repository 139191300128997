import './style.css'
import React, { useState, useEffect } from 'react'
import Single from './single'

function Three(props) {
    const len = 3
    const [numArr, setNumArr] = useState([])
    useEffect(() => {
        const propsNumArr = (props?.value || 0).toString().split('')
        if (propsNumArr.length < len) {
            const max = len - propsNumArr.length
            for(let i = 0; i < max;i++) {
                propsNumArr.unshift(0)
            }
        } else {
            propsNumArr.splice(0, propsNumArr.length - len)
        }
        setNumArr(propsNumArr)
    }, [props.value])
    return <>
        <Single value={numArr[0]} numStyle={props.numStyle} />
        <Single value={numArr[1]} numStyle={props.numStyle} />
        <Single value={numArr[2]} numStyle={props.numStyle} />
    </>
}

export default Three
