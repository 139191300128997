import axios from "axios";
import PapaParse from 'papaparse'

function csvJSON(csv) {
  if (!Array.isArray(csv) || !csv.length) return []
  if (csv[csv.length - 1].length <=1) csv.pop()
  if (csv.length<=1) return []
  const title = csv[0]
  const result = []

  for (let i = 1; i < csv.length; i++) {
    const obj = {}
    title.forEach((item, index) => {
      obj[item] = csv[i][index]
    })

    result.push(obj)
  }
  return result
}


// create an axios instance
const service = axios.create({
    baseURL: '/files', // url = base url + request url
    withCredentials: true, // send cookies when cross-domain requests
    timeout: 60000, // request timeout
    responseType: 'blob',
    // contentType: 'application/text;charset=gbk'
  })
  
  // request interceptor
  service.interceptors.request.use(
    config =>  config,
    error => {
      // do something with request error
      return Promise.reject(error)
    }
  )
  
  // response interceptor
  service.interceptors.response.use(
    async (response) => {
      let jsonResult
      PapaParse.parse(response.data, {
        encoding: 'gbk',
        complete: (result) => {
          jsonResult = csvJSON(result.data)
        }
      })
      await new Promise((resolve,reject) => {
        setTimeout(() => {
          resolve()
        }, 50)
      })
      return jsonResult
    },
    error => {
      return Promise.reject(error)
    }
  )
  
  export default service