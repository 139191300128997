import React from 'react';
import './style/global.css';
import 'animate.css';
import "@arco-design/web-react/dist/css/arco.css";
import ReactDOM from 'react-dom/client';
import Layout from './Layout';
import { Provider } from 'react-redux'
import store from './store'


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <Layout />
  </Provider>
  // </React.StrictMode>
);


